<template>
  <div>
    <div class="search">
      <el-input placeholder="请输入装备名称查询" style="width: 200px; padding-right: 10px;" v-model="name"></el-input>
      <el-input placeholder="请输入生产厂商查询" style="width: 200px; padding-right: 10px;" v-model="manufacturer"></el-input>
      <el-input placeholder="请输入在用站点查询" style="width: 200px; padding-right: 10px;" v-model="currentLocation"></el-input>
      <el-date-picker placeholder="请输入检定有效期查询"
          v-model="validperiod"
          type="date"
          size="small"
          value-format="yyyy-MM-dd"
                      :picker-options="pickerOptions">
      </el-date-picker>
      <el-button type="info" plain style="margin-left: 10px" @click="load(1)">查询</el-button>
      <el-button type="warning" plain style="margin-left: 10px" @click="reset">重置</el-button>
    </div>

    <div class="operation">
      <el-button type="primary" plain @click="handleAdd1">导出</el-button>
      <el-button type="danger" plain @click="delBatch1">打印</el-button>
    </div>
    <!--  二维码实例，要隐藏起来，目的生成二维码的图片  -->
<!--    <div id="qrcode" ref="qrcode" />-->
    <div class="qrCode">

    <el-dialog
        :visible.sync="dialogVisible"
        width="200px" top="25vh"
        :before-close="handleClose">
      <div id="qrcode" ref="qrcode" />
    </el-dialog>
  </div>

    <div class="table">
      <el-table :data="tableData" strip
                :row-style="{ height: '16px' }" :cell-style="{ padding: '2px' }"
                @selection-change="handleSelectionChange"
                stripe border :header-cell-class-name="'headerBg'" >
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column prop="id" label="序号" width="50" align="center"></el-table-column>
        <el-table-column label="二维码(点击显二维码)" width="150" align="center">
        <template v-slot="scope">
          <span @click="scope.row.qrcode === '无' ? '' : qrcodeToImg(scope.row.qrcode)" slot="reference">{{ scope.row.qrcode }}</span>
        </template>
        </el-table-column>
        <el-table-column prop="name" label="名称" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="manufacturer" label="生产商" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="在用站点" width="120" align="center">
          <template v-slot="scope">
            <span style="font-weight: 600; color: #e56c3c">{{ scope.row.currentLocation.substring(scope.row.currentLocation.indexOf('/')+1).substring(0,scope.row.currentLocation.substring(scope.row.currentLocation.indexOf('/')+1).indexOf('(')) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="鉴定人" width="68" align="center">
          <template v-slot="scope">
            <span>{{ scope.row.inspector === '无信息' ? '' : scope.row.inspector }}</span>
          </template>
        </el-table-column>
        <el-table-column label="鉴定结果" width="70" align="center">
          <template v-slot="scope">
            <span>{{ scope.row.testResults === '无信息' ? '' : scope.row.testResults }}</span>
          </template>
        </el-table-column>
        <el-table-column label="有效期" width="95" align="center">
          <template v-slot="scope">
            <span v-if="scope.row.validperiod === '无信息'"></span>
            <span v-else>
              <span v-if="new Date().getTime() - new Date(scope.row.validperiod).getTime()  < -2598521386">{{scope.row.validperiod}}</span>
              <span v-else-if="new Date().getTime() - new Date(scope.row.validperiod).getTime()  < 0"style="color: burlywood;font-weight: 800">{{scope.row.validperiod}}</span>
              <span v-else style="color: red;font-weight: 800">{{scope.row.validperiod}}</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="oldcode" label="旧二维码" width="150" align="center">
          <template v-slot="scope">
            <span @click="scope.row.oldCode === '无' ? '' : qrcodeToImg(scope.row.oldCode)" slot="reference">{{ scope.row.oldCode==='无'?'':scope.row.oldCode }}</span>
          </template>
        </el-table-column>
        <el-table-column label="靓照" width="100" align="center">
          <template v-slot="scope">
            <div style="display: flex; align-items: center">
              <el-image style="height: 40px; border-radius: 1%" v-if="scope.row.imgPath"
                        :src="scope.row.imgPath" :preview-src-list="[scope.row.imgPath]"></el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="220">
          <template v-slot="scope">
            <el-button size="mini" type="success" plain @click="handleCirculation(scope.row)">流转</el-button>
            <el-button size="mini" type="primary" plain @click="handleEdit(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" plain @click="del(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="[5, 10, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>

    <div class="circulation">
      <el-dialog :visible.sync="circulationVisible" width="40%" :close-on-click-modal="false" destroy-on-close>
        <div style="background: orange; border-radius: 20px;">
          <div style="font-size: 18px;text-align: center;padding: 10px;">装备流转</div>
          <div style="background: #ffc9b4; font-size: 20px;">
            <div style="font-size: 20px; text-align: center; padding: 10px;">请选择装备要流转到下列储存仓库</div>
            <div style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
              <el-radio-group v-model="radio">
                <div><el-radio :label="3" style="font-size: 24px;">区域站备件仓库</el-radio></div>
                <div ><el-radio :label="6">新型站备件仓库</el-radio></div>
                <div ><el-radio :label="9">备份站在用设备仓库</el-radio></div>
              </el-radio-group>
            </div>
          </div>
          <div style="padding: 20px 40px; text-align: right;">
            <el-button @click="circulationVisible = false">取 消</el-button>
            <el-button type="primary" @click="circulationSave">确 定</el-button>
          </div>
        </div>
      </el-dialog>
    </div>


    <el-dialog title="装备编辑" :visible.sync="fromVisible" width="200" :close-on-click-modal="false" destroy-on-close>
      <el-form :model="form" label-width="100px" style="padding-right: 50px" :rules="rules" ref="formRef">
        <el-form-item label="装备名称">
          <el-input v-model="form.name" disabled></el-input>
        </el-form-item>
        <el-form-item label="在用站点">
          <el-input v-model="form.currentLocation" disabled></el-input>
        </el-form-item>
        <el-form-item label="鉴定员" prop="inspector">
          <el-input v-model="form.inspector" placeholder="鉴定员"></el-input>
        </el-form-item>
        <el-form-item label="鉴定结果" prop="testresults">
          <el-input v-model="form.testResults" placeholder="鉴定结果"></el-input>
        </el-form-item>
        <el-form-item label="鉴定有效期" prop="validperiod">
          <el-date-picker placeholder="请输入检定有效期查询"
                          v-model="form.validperiod"
                          type="date"
                          size="small"
                          value-format="yyyy-MM-dd"
                          :picker-options="pickerOptions1">
          </el-date-picker>

<!--          <el-input v-model="form.validperiod" placeholder="鉴定有效期"></el-input>-->
        </el-form-item>
        <el-form-item label="靓照">
          <el-upload
              class="avatar-uploader"
              :action="$baseUrl + '/files/upload1'"
              :headers="{ token: user.token }"
              list-type="picture"
              :on-success="handleAvatarSuccess"
          >
            <el-button type="primary">上传靓照</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="fromVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>


  </div>
</template>

<script>
import QRCode from 'qrcode2'
export default {
  name: "assets-using",
  data() {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一月后',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 30);
            picker.$emit('pick', date);
          }
        }]
      },
      pickerOptions1: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }]
      },
      qrcode: null,   // 二维码实例
      dialogVisible: false,
      tableData: [],  // 所有的数据
      pageNum: 1,   // 当前的页码
      pageSize: 10,  // 每页显示的个数
      total: 0,
      name: null,      // 装备名称
      manufacturer: null,      // 生产厂商
      currentLocation: null,   // 在用站点
      validperiod: null,      // 检定有效期
      fromVisible: false,
      circulationVisible: false,
      form: {},
      user: JSON.parse(localStorage.getItem('user') || '{}'),
      rules: {
        username: [
          {required: true, message: '请输入账号', trigger: 'blur'},
        ]
      },
      ids: [],
      radio: 3,
    }
  },
  created() {
    this.load(1)
  },
  mounted() {
    // //生成二维码实例
    // this.qrcode = new QRCode('qrcode', {
    //   typeNumber: 4,
    //   width: 200, // 设置宽度，单位像素
    //   height: 200, // 设置高度，单位像素
    //   colorLight: '#fff', //二维码背景色
    //   text: 'G110700136900005000202309070008232' // 设置二维码内容或跳转地址
    // })
    // console.log('>>>>>>>>>>>>>>>',this.qrcode)
  },
  methods: {
    handleAdd() {   // 新增数据
      this.form = {}  // 新增数据的时候清空数据
      this.fromVisible = true   // 打开弹窗
    },
    handleEdit(row) {   // 编辑数据
      this.form = JSON.parse(JSON.stringify(row))  // 给form对象赋值  注意要深拷贝数据
      this.fromVisible = true   // 打开弹窗
    },
    save() {   // 保存按钮触发的逻辑  它会触发新增或者更新
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.request.post('/equipment', this.form).then(res => {
            if (res.code === '200') {  // 表示成功保存
              this.$message.success('保存成功')
              this.load(1)
              this.fromVisible = false
            } else {
              this.$message.error(res.msg)  // 弹出错误的信息
            }
          })
        }
      })
    },
    del(id) {   // 单个删除
      this.$confirm('您确定删除吗？', '确认删除', {type: "warning"}).then(response => {
        this.request.delete('/equipment/' + id).then(res => {
          if (res.code === '200') {   // 表示操作成功
            this.$message.success('操作成功')
            this.load(1)
          } else {
            this.$message.error(res.msg)  // 弹出错误的信息
          }
        })
      }).catch(() => {
      })
    },
    handleSelectionChange(rows) {   // 当前选中的所有的行数据
      this.ids = rows.map(v => v.id)
    },
    delBatch() {   // 批量删除
      if (!this.ids.length) {
        this.$message.warning('请选择数据')
        return
      }
      this.$confirm('您确定批量删除这些数据吗？', '确认删除', {type: "warning"}).then(response => {
        this.request.delete('/equipment/delete/batch', {data: this.ids}).then(res => {
          if (res.code === '200') {   // 表示操作成功
            this.$message.success('操作成功')
            this.load(1)
          } else {
            this.$message.error(res.msg)  // 弹出错误的信息
          }
        })
      }).catch(() => {
      })
    },
    load(pageNum) {  // 分页查询
      if (pageNum) this.pageNum = pageNum
      this.request.get('/equipment/page', {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          status: ['在用'] + '', //this.status,
          name: this.name,      // 装备名称
          manufacturer: this.manufacturer,      // 生产厂商
          currentLocation: this.currentLocation,   // 在用站点
          validperiod: this.validperiod,      // 检定有效期
        }
      }).then(res => {
        console.log(res.data)
        this.tableData = res.data?.records
        this.total = res.data?.total


      })
    },
    reset() {
      this.name = this.manufacturer = this.currentLocation = this.validperiod = null
      this.load(1)
    },
    handleCurrentChange(pageNum) {
      this.load(pageNum)
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize=val
      this.load(1)
    },

    handleAvatarSuccess(response, file, fileList) {
      // 把头像属性换成上传的图片的链接
      this.form.imgpath = response.data
    },
    handleCirculation(){//流转按钮
      this.circulationVisible = true
    },
    circulationSave(){
      this.circulationVisible = false
    },
    qrcodeToImg(text) {
      this.dialogVisible=true
      if (this.qrcode) {
        console.log('二维码:', this.qrcode)
        this.qrcode.clear(); // 清除原来的二维码, 这里是动态生成DIV， 所以this.qrcode
        this.qrcode.makeCode(text)
      }else{
        // 生成二维码实例
        console.log('二维码1:', this.qrcode)
        this.qrcode = new QRCode('qrcode', {
          typeNumber: 4,
          width: 200, // 设置宽度，单位像素
          height: 200, // 设置高度，单位像素
          //     colorDark: 'red', //二维码前景色
          colorLight: '#fff', //二维码背景色
          text: text // 设置二维码内容或跳转地址
        })

      }
    }
  }

}
</script>

<style scoped>
/*   el-dialog样式修改 */
::v-deep .qrCode .el-dialog {
  background-color: darkgrey;
}
::v-deep .qrCode .el-dialog__header {
  display: none;
}
::v-deep .qrCode .el-dialog__body {
  padding: 0px;
  color: black;
  caret-color: transparent;
}
::v-deep .qrCode .el-dialog__footer {
  display: none;
}
::v-deep .qrCode .el-dialog__headerbtn {
  display: none;
}
/*  流转对话框el-dialog样式修改 */
::v-deep .circulation .el-dialog {
  width: 400px!important;
  border-radius: 10px;
  background-color: orange!important;
}
::v-deep .circulation .el-dialog__header {
  display: none;
  padding: 10px;
  color: white;
  caret-color: transparent;
}
::v-deep .circulation .el-dialog__body {
  padding: 0px;
  color: black;
  border-radius: 20px;
  background-color: orange!important;
  caret-color: transparent;
}
::v-deep .circulation .el-dialog__footer {
  display: none;
}
::v-deep .circulation .el-dialog__headerbtn {
  /*display: none;*/
}



/* 未选中的字体颜色 */
::v-deep .el-radio__input + .el-radio__label {
  font-size: 16px!important;
  /*font-weight: 700!important;*/
  margin: 10px 0!important;
  /*color: #28d4c1 !important;*/
}
/*选中后小圆点的颜色*/
::v-deep .el-radio__input .el-radio__inner {
  width: 16px !important;
  height: 16px !important;
  margin: 10px 0!important;
  /*background: #28d4c1 !important;*/
  /*border-color: #28d4c1 !important;*/
}
/* 选中后的字体颜色 */
::v-deep .el-radio__input.is-checked + .el-radio__label {
  font-size: 16px!important;
  font-weight: 700!important;
  color: lightseagreen !important;
}
 /*选中后小圆点的颜色*/
::v-deep .el-radio__input.is-checked .el-radio__inner {
   width: 16px !important;
   height: 16px !important;
  background: lightseagreen !important;
  border-color: lightseagreen !important;
}


</style>



